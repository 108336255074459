import React from "react";
import { AppProvider } from "@context/AppContext";

import "./src/styles/cookies.css";

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);

export const onRouteUpdate = () => {
  if (
    document.querySelector("#onetrust-banner-sdk") &&
    document.querySelector("#onetrust-banner-sdk").style.visibility !== "hidden"
  ) {
    document.querySelector("header").classList.add("onetrust-banner-open");
  } else {
    document.querySelector("header").classList.remove("onetrust-banner-open");
  }
};
